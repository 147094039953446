.showInstruction {
	width: 41px;
    height: 41px;
    border-radius: 50%;
    text-align: center;
	margin: auto;
	margin-top: 10px;
	background-color: white;
	border: 1px solid #b3b8cc;
	color: navy;
	font-size: 18px;
	font-weight: bold;
}

.showInstruction:hover {
	background-color: navy;
    border: 1px solid navy;
    color: white;
}

.imageExample {
	width: 100%;
}

.textExample {
    font-size: 14px;
    width: 80%;
    margin: auto;
    text-align: justify;
    margin-bottom: 1rem;
}
