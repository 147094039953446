




.tilesetCard {
	margin: auto;
	margin-bottom: 20px;
	border: 2px solid #a7afc6;

}

.tilesetHidden {
	background-color: #d2d2d2;
    opacity: 0.6;
}

.tilesetDate {
	margin-top: -10px;
}



@media (max-width: 768px) {
	.tilesetCard {
    	max-width: 150px;
        min-width: 150px !important;
        height: 150px;
    }
    .tilesetContainer {
    	max-width: 50%;
    }
}

@media (min-width: 768px) {
	.tilesetCard {
    	margin: auto;
    	margin-bottom: 20px;
    	max-width: 200px;
        min-width: 200px !important;
        height: 150px;
    }
    .tilesetContainer {
    	max-width: 100%;
    }
}

@media (max-width: 768px) {
	.squareTilesetImage {
		width: 100px;
	}
}
@media (min-width: 768px) {
	.squareTilesetImage {
		width: 135px;
	}
}

.squareTilesetImage {
    margin: auto;
    margin-top: 8px;
    border: 2px solid black;
    border-radius: unset;
}


.newTileset {
	filter: blur(2px) grayscale(2) opacity(0.5);
}

.levelStar {
	height: 20px;
}
.biggerStar {
	height: 40px;
	margin-bottom: 10px;
}

.greyStar {
	filter: grayscale(1);
}

.card-body {
	padding: 10px 2px;
}

.card-title {
	height: 30px;
	margin-bottom: -5px;
	font-size: 1rem !important;
}

.card-text {
	height: 25px;
	margin-bottom: 5px;
}

.comments {
	background-color: white;
	padding-top: 20px;
	padding-bottom: 50px;
}
.commentsContact {
	padding-top: 20px;
	padding-bottom: 30px;
	padding-left: 0px;
	padding-right: 0px;
}

.gamePicker {
	background-color: #7dadaf;
	border-radius: 0px 0px 0px 0px;
	border-bottom: none;
	cursor: pointer;
	margin-bottom:0px !important;
	padding-left: 0px;
	padding-right: 0px;
}

.gamePicked {
	background-color: #fff;
}

@media (min-width: 768px) {
	.gamePicker {
		height: 70px;
        font-size: 25px;
        line-height: 30px;
        margin: 10px 10px;
	}
	.gameSmallStar {
		height: 22px;
	    margin-top: -6px;
	}
	.card-title {
		font-size: 1.25rem !important;
	}
}

@media (min-width: 450px) and (max-width: 768px) {
	.gamePicker {
		height: 55px;
        font-size: 20px;
        line-height: 25px;
        margin: 10px 7px;
	}
	.gameSmallStar {
		height: 18px;
	    margin-top: -6px;
	}
}


@media (max-width: 450px) {
	.gamePicker {
		height: 45px;
        font-size: 15px;
        line-height: 20px;
        margin: 10px 3px;
	}
	.gameSmallStar {
		height: 15px;
	    margin-top: -6px;
	}
}

.light-btn {
	background-color: #346718 !important;
	border-color: #346718 !important;
}

