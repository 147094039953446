#whatsappShare {
	background-color: #029031;
	height: 37px;
	width: 200px;
}

#whatsappShare a {
	color: white !important;
	text-decoration: none;
}

#whatsappShare svg {
    height: 22px;
    width: 22px;
    margin-right: 5px;
    margin-top: -4px;
}

#twitterShare {
	background-color: #f3f7fa;
	border: 1px solid #f3f7fa;
	padding: 6px 30px !important;
	color: black !important;
	text-decoration: none;
	height: 37px;
	width: 200px;
}

#twitterShare svg {
	height: 22px;
    width: 22px;
    margin-right: 5px;
    padding: 2px 2px;
    margin-top: -2px;
}

.linkCopied {
	font-size: 18px;
}

.btn-share {
	margin: 5px;
}