
.tilesNavbar {
	border-bottom: 3px solid #011050;
}

.navbar-brand {
	margin: auto !important;
    font-size: 26px !important;
    line-height: 48px;
    font-weight: bold;
    cursor: pointer;
    max-width: 75%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 10px !important;

}

.tilesNews {
	background-color: #faff00 !important;
	border-bottom: 3px solid #011050;
	padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
}

.tilesNews a,
.tilesNews span {
	margin: auto;
	font-size: 18px;
	text-decoration: none !important;
	text-align: center;
}

.languagesDropdown {
	border-color: transparent !important;
    background-color: transparent !important;
    display: contents;
}

.dropdownMenu {
	width: 65px !important;
    min-width: 40px !important;
    border-radius: 0px;
}

.languagesDropdown img,
.dropdownMenu img {
	width: 30px;
}

@media (max-width: 420px) {
	.navbar-brand {
		font-size: 23px !important;
	}
	.languagesDropdown img,
    .dropdownMenu img {
    	width: 25px;
    }
}

.navbar-icon {
	width: 30px;
	height: 30px;
	margin-left: 15px;
}